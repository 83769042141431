.curved-menu {
    z-index: 1001;
}

.navbar {
    background: linear-gradient(0deg, #E3EDF7, #E3EDF7);
    justify-content: space-between;
}

.navbar .logo-link {
    padding: 0;
}

.navbar .logo-link img {
    width: 32px;
    height: 32px;
}

.navbar .logo-link .logo-title {
    font-weight: 600;
    position: relative;
    top: -0.2rem;
    color: #3D516F;
}

.navbar .logo-link .nav-embellish {
    font-size: 72.5%;
    font-weight: 700;
    position: relative;
    top: -0.2rem;
    color: #3D516F;
}

.navbar .navbar-toggler {
    border: 0;
}

.navbar .navbar-toggler:focus {
    outline: none;
}

.navbar .navbar-toggler img {
    width: 50%;
}

.curved-menu .nav-link {
    font-size: 1rem;
    font-weight: 700;
    color: #3d516f;
}

.dropdown-toggle.nav-link {
    min-width: 8rem;
    text-align: center;
}

.curved-menu .nav-link.active {
    color: #3d516f;
}

.curved-menu .list-group-item-text .nav-link {
    padding-left: 0;
    font-weight: 300;
}

.community-menu .nav-item .nav-link {
    text-align: left;
}

.curved-menu .separator {
    border-top: 1px solid #3d516f67;
}

.community-menu .list-group,
.products-menu .list-group {
    box-shadow: none;
}

.community-menu .list-group-item,
.products-menu .list-group-item {
    border: none;
    background: transparent;
    text-align: left;
    position: relative;
}

.community-menu .dropdown-item.active,
.community-menu .dropdown-item:active,
.products-menu .dropdown-item.active,
.products-menu .dropdown-item:active {
    color: #3D516F;
    text-decoration: none;
    background-color: transparent;
}

.community-menu .dropdown-item:hover,
.community-menu .dropdown-item:focus,
.products-menu .dropdown-item:hover,
.products-menu .dropdown-item:focus {
    color: #3D516F;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    border: none;
}

.community-menu .nav-link img {
    width: 1rem;
    height: 1rem;
}

.community-menu .nav-link span.social-text {
    font-size: 0.75rem;
    font-weight: 400;
}

.spac-menu {
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(0, 0, 0, 0.72)!important;
    transition-property: background-color, backdrop-filter;
    background-image: none;
    z-index: 10;
}

@media only screen and (max-width: 734px) {
    .spac-menu {
        transform: translate3d(0, -1px, 0) !important;
    }
}

.spac-menu .label {
    color: #fff;
    font-weight: 900;
}

.spac-menu .nav-link {
    color: #fff!important;
    font-weight: 700;
    font-size: 14px;
}

.footer-menu {
    width: 100%;
    max-width: 1068px;
    z-index: 10;
}

.footer-menu .list-group-item {
    border: none;
    background: transparent;
    text-align: left;
    position: relative;
}

.footer-menu .list-group-item-text .nav-link {
    padding: 0;
    color: inherit;
}

.footer-menu .curved-copyright-header {
    margin-top: 3rem;
}

.footer-menu .curved-copyright-text {
    margin-top: 2rem;
}

.footer-menu .list-group-item-heading {
    margin-top: 3rem;
}

.footer-menu .list-group-item .logo-title {
    font-weight: 600;
    position: relative;
    top: -0.2rem;
    color: #3D516F;
}

.footer-menu .list-group-item .nav-embellish {
    font-size: 72.5%;
    font-weight: 700;
    position: relative;
    top: -0.2rem;
    color: #3D516F;
}

.footer-menu .contact-form {
    width: 100%;
}

.footer-menu .contact-form button {
    font-size: 80%;
}

@media only screen and (max-width: 734px) {
    .footer-menu .contact-form {
        width: 75%;
    }
}

@media (max-width: 576px) {
    .dropdown-toggle::after {
        display: none!important;
    }

    .community-menu,
    .products-menu {
        border: none;
        box-shadow: none;
        background-color: transparent;
}

    .community-menu .dropdown-item,
    .products-menu .dropdown-item {
        flex-direction: column;
        padding: 0!important;
    }

    .products-menu .dropdown-item .list-group .list-group-item {
       padding: 0;
    }

    .products-menu .dropdown-item .list-group-item-heading {
        display: none;
    }

    .community-menu .form-group {
        margin-top: 0.25rem!important;
        margin-bottom: 0.5rem!important;
    }
}
